import React from 'react'
import FullLogo from './assets/images/fullLogo.svg'
import Burger from './assets/images/burger.svg'
import { useEffect, useState } from 'react'
import './assets/css/Header.css'
import { NavLink } from 'react-router-dom'

function Header() {
    const [toggleMenu, setToggleMenu] = useState(false)

    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
        if (!toggleMenu) {
            document.body.style.overflowY = "hidden"
        }
        else {
            document.body.style.overflowY = "auto"
        }
    }

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])
    const [isShown, setIsShown] = useState(false);
    return (
        <div>
            <div className='fixed-top'>
                <div className='px-4 px-lg-5 navBg'>
                    <div className="row g-0">
                        <div className={` ${(screenWidth < 786.98) ? "col" : "col-auto"} my-auto pt-1`}>
                            <NavLink style={({ isActive }) => ({
                                color: isActive ? '#2F60DC' : '#000',
                            })} to='/'>
                                <img src={FullLogo} className="fullLogo" style={{ zIndex: 9999, position: "relative" }} alt="" />
                            </NavLink>
                        </div>
                        <div className={` ${(screenWidth < 786.98) ? "col-auto" : "col"} my-auto`}>
                            {(screenWidth < 786.98) && (
                                <div>
                                    <div className='' onClick={toggleNav}>
                                        <div className=''>
                                            <div className="row g-0">
                                                <div className="col my-auto">
                                                    <div className=''>
                                                        <img src={Burger} alt="" />
                                                    </div>
                                                </div>
                                                {/* <div className="col-auto my-auto py-1">
                                                    <div>
                                                        Menu
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`navTabs ${toggleMenu && 'navTabsOpen'}`}>
                                        <div className='px-4' style={{ padding: '0.75rem 0rem 0.75rem 0rem' }}>
                                            <div className="row g-0" >
                                                <div className="col">
                                                    <NavLink style={({ isActive }) => ({
                                                        color: isActive ? '#2F60DC' : '#000',
                                                    })} to='/'>
                                                        <img src={FullLogo} className="fullLogo invisible" alt="logo" />
                                                    </NavLink>
                                                </div>
                                                <div className="col-auto my-auto pe-1" onClick={toggleNav}>
                                                    <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13 1L1 13" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M1 1L13 13" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pt-5 mt-5' >
                                            <div className='px-4' >
                                                <div className="" >
                                                    <NavLink style={({ isActive }) => ({
                                                        color: isActive ? '#2F60DC' : '#000',
                                                    })} onClick={toggleNav} to="/" className='navLinks fs-5'>
                                                        <span >
                                                            Home
                                                        </span>
                                                    </NavLink>
                                                </div>
                                                <hr className='my-3' style={{ height: '2px', opacity: '1', color: '#000' }}></hr>
                                                <div className="pb-3">
                                                    {/* <NavLink style={({ isActive }) => ({
                                        color: isActive ? '#2F60DC' : '#000',
                                    })} onClick={toggleNav} to="/Privatkunden" > */}
                                                    <div className='navLinks'>
                                                        <span >
                                                            Privatkunden
                                                        </span>
                                                        <div>
                                                            <div>

                                                                <div className='py-3'>
                                                                    <NavLink style={({ isActive }) => ({
                                                                        color: isActive ? '#2F60DC' : '#000',
                                                                    })} to='/Privatkunden/Versicherungen' id="linksss" onClick={toggleNav} className='text-decoration-none navLinks ps-3'>
                                                                        <span>Versicherungen</span>

                                                                    </NavLink>
                                                                </div>
                                                                <div className=''>
                                                                    <NavLink style={({ isActive }) => ({
                                                                        color: isActive ? '#2F60DC' : '#000',
                                                                    })} to='/Privatkunden/Finanzen' id="linksss" onClick={toggleNav} className='text-decoration-none navLinks ps-3'>
                                                                        <span>Finanzen</span>

                                                                    </NavLink>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* </NavLink> */}
                                                </div>
                                                <div className="pb-3">
                                                    <NavLink style={({ isActive }) => ({
                                                        color: isActive ? '#2F60DC' : '#000',
                                                    })} onClick={toggleNav} to="/Geschaftskunden" className='navLinks fs-5'>
                                                        <span >
                                                            Geschäftskunden
                                                        </span>
                                                    </NavLink>
                                                </div>
                                                <div className="pb-3">
                                                    <NavLink style={({ isActive }) => ({
                                                        color: isActive ? '#2F60DC' : '#000',
                                                    })} onClick={toggleNav} to="/DLF" className='navLinks fs-5'>
                                                        <div className="row g-0">
                                                            <div className="col-auto me-1">
                                                                <span >
                                                                    DLF
                                                                </span>
                                                            </div>

                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="pb-3">
                                                    <NavLink style={({ isActive }) => ({
                                                        color: isActive ? '#2F60DC' : '#000',
                                                    })} onClick={toggleNav} to="/Uber-uns" className='navLinks fs-5'>
                                                        <div className="row g-0">
                                                            <div className="col-auto me-1">
                                                                <span >
                                                                    Über uns
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(screenWidth > 786.98) && (
                                <div>
                                    <div className="row g-4 g-xl-5 justify-content-center" >
                                        <div className="col-auto">
                                            <NavLink style={({ isActive }) => ({
                                                color: isActive ? '#2F60DC' : '#000',
                                            })} to="/" className='navLinks' >
                                                <span >
                                                    Home
                                                </span>
                                            </NavLink>
                                        </div>
                                        <div className="col-auto">
                                            <span className='navLinks position-relative' onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
                                                Privatkunden
                                                {isShown && (
                                                    <div className='navbarDropdownsWrap' onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
                                                        <div className='navbarDropdowns' >
                                                            <div className='pb-3'>
                                                                <NavLink style={({ isActive }) => ({
                                                                    color: isActive ? '#2F60DC' : '#000',
                                                                })} to='/Privatkunden/Versicherungen' id="linksss" className='text-decoration-none navLinks px-3'>
                                                                    Versicherungen
                                                                </NavLink>
                                                            </div>
                                                            <div className=''>
                                                                <NavLink style={({ isActive }) => ({
                                                                    color: isActive ? '#2F60DC' : '#000',
                                                                })} to='/Privatkunden/Finanzen' id="linksss" className='text-decoration-none navLinks px-3'>
                                                                    Finanzen
                                                                </NavLink>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )}
                                            </span>
                                        </div>
                                        <div className="col-auto">
                                            <NavLink style={({ isActive }) => ({
                                                color: isActive ? '#2F60DC' : '#000',
                                            })} to="/Geschaftskunden" className='navLinks'>
                                                <span >
                                                    Geschäftskunden
                                                </span>
                                            </NavLink>
                                        </div>
                                        <div className="col-auto">
                                            <NavLink style={({ isActive }) => ({
                                                color: isActive ? '#2F60DC' : '#000',
                                            })} to="/DLF" className='navLinks'>
                                                <div className="row g-0">
                                                    <div className="col-auto me-1">
                                                        <span >
                                                            DLF
                                                        </span>
                                                    </div>

                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-auto">
                                            <NavLink style={({ isActive }) => ({
                                                color: isActive ? '#2F60DC' : '#000',
                                            })} to="/Uber-uns" className='navLinks'>
                                                <div className="row g-0">
                                                    <div className="col-auto me-1">
                                                        <span >
                                                            Über uns
                                                        </span>
                                                    </div>

                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                            )}
                        </div>
                        {(screenWidth > 991.98) && (
                            <div className="col-auto my-auto">
                                <NavLink style={({ isActive }) => ({
                                    color: isActive ? '#2F60DC' : '#000',
                                })} to='/Angebot'>
                                    <button className='homeFirstSection py-2'>
                                        Angebot einholen
                                    </button>
                                </NavLink>

                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header