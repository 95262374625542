import React from 'react'
import { useState } from 'react'
import FaqIlu from '../../assets/images/About/faq.svg'
function Faq() {
    const [showFaq, setShowFaq] = useState(0)
    const toggleFaqState = (e) => {
        if (showFaq === e) {
            setShowFaq(0)
        }
        else {
            setShowFaq(e)

        }
    }
    return (
        <div>
            <div className="pt-5 container-lg px-4 px-md-5 pb-5 mb-5 col-12 col-lg-10">
                <div className='pb-3 mb-3' style={{ borderBottom: '2px solid #e9e9e9' }}>
                    <span className='faqTitle'>Finanzberater/in 100%</span>
                </div>
                <div className='mb-3' style={{ borderBottom: '2px solid #e9e9e9' }}>
                    <div className='pb-3' onClick={() => { toggleFaqState(1) }} style={{ cursor: 'pointer' }}>
                        <div className="row g-0" >
                            <div className="col">
                                <div>
                                    <span className='faqSecondTitle'>Ihr Aufgabenbereich</span>
                                </div>
                            </div>
                            <div className="col-auto my-auto">
                                {(showFaq === 1) ?
                                    <svg width="14" height="14" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.16194 11.3301C0.845537 11.6465 0.83047 12.2115 1.16948 12.543C1.50095 12.8745 2.06596 12.867 2.38237 12.5505L6.99286 7.93252L11.6109 12.5505C11.9348 12.8745 12.4923 12.8745 12.8238 12.543C13.1477 12.204 13.1552 11.6541 12.8238 11.3301L8.21328 6.7121L12.8238 2.10161C13.1552 1.77767 13.1552 1.22019 12.8238 0.888715C12.4848 0.564775 11.9348 0.557242 11.6109 0.881181L6.99286 5.49921L2.38237 0.881181C2.06596 0.564775 1.49342 0.549708 1.16948 0.888715C0.838003 1.22019 0.845537 1.7852 1.16194 2.10161L5.77997 6.7121L1.16194 11.3301Z" fill="black" />
                                    </svg>
                                    :
                                    <>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.54534 7.56543H6.14076V12.1609C6.14076 12.6279 6.52497 13.0197 6.99958 13.0197C7.47419 13.0197 7.8584 12.6279 7.8584 12.1609V7.56543H12.4538C12.9209 7.56543 13.3126 7.18122 13.3126 6.70661C13.3126 6.232 12.9209 5.8478 12.4538 5.8478H7.8584V1.25237C7.8584 0.785296 7.47419 0.393555 6.99958 0.393555C6.52497 0.393555 6.14076 0.785296 6.14076 1.25237V5.8478H1.54534C1.07826 5.8478 0.686523 6.232 0.686523 6.70661C0.686523 7.18122 1.07826 7.56543 1.54534 7.56543Z" fill="black" />
                                        </svg>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={` ${(showFaq === 1) ? "faqOpen" : "faqClosed"}`}>
                        <span className='faqThirdTitle'>
                            Bedürfnisorientierte Beratung von Privatkunden im Bereich Vorsorge und Vermögen sowie in der Gesundheitsvorsorge
                            <br></br>
                            Konitnuierliche Pflege der bestehenden Kundenbeziehungen
                            <br />
                            Gewinnung von Neukunden und Aufbau von externen Netzwerken
                        </span>
                    </div>
                </div>
                <div className='mb-3' style={{ borderBottom: '2px solid #e9e9e9' }}>
                    <div className='pb-3' onClick={() => { toggleFaqState(2) }} style={{ cursor: 'pointer' }}>
                        <div className="row g-0" >
                            <div className="col">
                                <div>
                                    <span className='faqSecondTitle'>Ihre Qualifikation</span>
                                </div>
                            </div>
                            <div className="col-auto my-auto">
                                {(showFaq === 2) ?
                                    <svg width="14" height="14" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.16194 11.3301C0.845537 11.6465 0.83047 12.2115 1.16948 12.543C1.50095 12.8745 2.06596 12.867 2.38237 12.5505L6.99286 7.93252L11.6109 12.5505C11.9348 12.8745 12.4923 12.8745 12.8238 12.543C13.1477 12.204 13.1552 11.6541 12.8238 11.3301L8.21328 6.7121L12.8238 2.10161C13.1552 1.77767 13.1552 1.22019 12.8238 0.888715C12.4848 0.564775 11.9348 0.557242 11.6109 0.881181L6.99286 5.49921L2.38237 0.881181C2.06596 0.564775 1.49342 0.549708 1.16948 0.888715C0.838003 1.22019 0.845537 1.7852 1.16194 2.10161L5.77997 6.7121L1.16194 11.3301Z" fill="black" />
                                    </svg>
                                    :
                                    <>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.54534 7.56543H6.14076V12.1609C6.14076 12.6279 6.52497 13.0197 6.99958 13.0197C7.47419 13.0197 7.8584 12.6279 7.8584 12.1609V7.56543H12.4538C12.9209 7.56543 13.3126 7.18122 13.3126 6.70661C13.3126 6.232 12.9209 5.8478 12.4538 5.8478H7.8584V1.25237C7.8584 0.785296 7.47419 0.393555 6.99958 0.393555C6.52497 0.393555 6.14076 0.785296 6.14076 1.25237V5.8478H1.54534C1.07826 5.8478 0.686523 6.232 0.686523 6.70661C0.686523 7.18122 1.07826 7.56543 1.54534 7.56543Z" fill="black" />
                                        </svg>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={` ${(showFaq === 2) ? "faqOpen" : "faqClosed"}`}>
                        <span className='faqThirdTitle'>
                            Abgeschlossene Berufslehre sowie Berufserfahrung im Verkauf oder idealerweise in der Versicherungsbranche
                            <br />
                            Überzeugendes Auftreten und Freude am Kontakt mit Menschen
                            <br />
                            Sehr gute Deutschkentnisse, jede weitere Sprache von Vorteil
                            <br />
                            Hohe Selbstständigkeit und Flexibilität (Arbeitszeit)
                            <br />
                            Ein eigenes Fahrzeug und gültiger Führerausweis Kat. B
                        </span>
                    </div>
                </div>
                <div className='mb-3' style={{ borderBottom: '2px solid #e9e9e9' }}>
                    <div className='pb-3' onClick={() => { toggleFaqState(3) }} style={{ cursor: 'pointer' }}>
                        <div className="row g-0" >
                            <div className="col">
                                <div>
                                    <span className='faqSecondTitle'>Ihre Perspektiven</span>
                                </div>
                            </div>
                            <div className="col-auto my-auto">
                                {(showFaq === 3) ?
                                    <svg width="14" height="14" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.16194 11.3301C0.845537 11.6465 0.83047 12.2115 1.16948 12.543C1.50095 12.8745 2.06596 12.867 2.38237 12.5505L6.99286 7.93252L11.6109 12.5505C11.9348 12.8745 12.4923 12.8745 12.8238 12.543C13.1477 12.204 13.1552 11.6541 12.8238 11.3301L8.21328 6.7121L12.8238 2.10161C13.1552 1.77767 13.1552 1.22019 12.8238 0.888715C12.4848 0.564775 11.9348 0.557242 11.6109 0.881181L6.99286 5.49921L2.38237 0.881181C2.06596 0.564775 1.49342 0.549708 1.16948 0.888715C0.838003 1.22019 0.845537 1.7852 1.16194 2.10161L5.77997 6.7121L1.16194 11.3301Z" fill="black" />
                                    </svg>
                                    :
                                    <>
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.54534 7.56543H6.14076V12.1609C6.14076 12.6279 6.52497 13.0197 6.99958 13.0197C7.47419 13.0197 7.8584 12.6279 7.8584 12.1609V7.56543H12.4538C12.9209 7.56543 13.3126 7.18122 13.3126 6.70661C13.3126 6.232 12.9209 5.8478 12.4538 5.8478H7.8584V1.25237C7.8584 0.785296 7.47419 0.393555 6.99958 0.393555C6.52497 0.393555 6.14076 0.785296 6.14076 1.25237V5.8478H1.54534C1.07826 5.8478 0.686523 6.232 0.686523 6.70661C0.686523 7.18122 1.07826 7.56543 1.54534 7.56543Z" fill="black" />
                                        </svg>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={` ${(showFaq === 3) ? "faqOpen" : "faqClosed"}`}>
                        <span className='faqThirdTitle'>
                            Attraktive Arbeitsbedingungen
                            <br />
                            Dynamisches und junges Team
                            <br />
                            Interne Schulungen mit Schweizweit anerkannten Zertifikaten (VBV, IAF etc.)
                            <br />
                            Überdurchschnittliche Einkommensperspektiven und Aufstiegsmöglichkeiten
                        </span>
                    </div>
                </div>
                <div className="pt-5 pb-0 pb-md-5">
                    <div className="row g-0">
                        <div className="col-12 col-sm-10 col-lg-10 col-xl-11 mt-auto">
                            <div className="faqBlueBg p-3 p-sm-4 pb-5 pb-sm-4">
                                <div className='pb-3 pt-3 pt-sm-0'>
                                    <div className='col-12 col-sm-10 col-lg-12'>
                                        <span style={{ fontSize: "17px", fontWeight: '700' }}>
                                        Haben wir Ihr Interesse geweckt? Dann freuen wir uns auf Ihre Bewerbung per E-Mail:
                                        </span>
                                    </div>

                                </div>
                                <div className='pb-3'>
                                    <span>info@dl-finance.ch</span>
                                </div>
                                <div className='pb-3'>
                                    <span>DL Finance</span>
                                </div>
                                <div className='pb-5 mb-5 pb-sm-0 mb-sm-0'>
                                    <span>HR Service Center</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-1 col-md-1 col-lg-2 col-xl-1 my-auto">
                            <div className="marginLeftFaq">
                                <img src={FaqIlu} alt="faq" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq