import React from 'react'
// import Wallet from '../../assets/images/wallet.svg'
import Versicherungen from '../../assets/images/versicherungen.svg'
import Vorsorge from '../../assets/images/vorsorge.svg'
import Steuerberatung from '../../assets/images/steuerberatung.svg'
import Finannzplanung from '../../assets/images/finannzplanung.svg'
import Hypotheken from '../../assets/images/hypotheken.svg'
import KMU from '../../assets/images/KMU.svg'
import { HashLink } from 'react-router-hash-link';
function HomeBlueBgSection() {
    return (
        <div>
            <div className='darkBlueBg'>
                <div className='py-5 px-5 px-sm-4 px-lg-5'>
                    <div className='py-5 text-center' style={{ color: '#fff' }}>
                        <div className=''>
                            <span className='fs-3' style={{ fontWeight: 600 }}>Erhalten Sie den DL Finance-Schutz für Ihre...</span>
                        </div>
                        <div>
                            <span className='fs-6' style={{ fontWeight: 400 }}>Ein schneller Überblick über das, was abgedeckt ist.</span>
                        </div>
                    </div>
                    <div className='container pb-5 text-center px-0'>
                        <div className='pb-0 pb-md-5'>
                            <div className="row gy-4 gy-lg-5 gx-0 gx-sm-4 gx-lg-5 pb-0 pb-md-5 mb-0 mb-md-5">
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <HashLink to="/Privatkunden/Finanzen#Vorsorgeberatung" className='text-decoration-none'>
                                        <div className="sliderCards p-2 p-md-4 h-100">
                                            <div className='pb-3 '>
                                                <img src={Vorsorge} alt="" />
                                            </div>
                                            <div className='pb-2'>
                                                <span style={{ fontWeight: 600 }}>Vorsorge</span>
                                            </div>
                                            <div>
                                                <div style={{ fontWeight: 300, fontSize: '14px', wordSpacing: '-1px' }}>Wir bieten Ihnen eine kompetente und umfassende Vorsorgeberatung, vereinbaren Sie mit uns einen Termin. </div>
                                            </div>
                                        </div>
                                    </HashLink>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <HashLink to="/Privatkunden/Finanzen#Finanzplanung" className='text-decoration-none'>
                                        <div className="sliderCards p-2 p-md-4 h-100">
                                            <div className='pb-3 '>
                                                <img src={Finannzplanung} alt="" />
                                            </div>
                                            <div className='pb-2'>
                                                <span style={{ fontWeight: 600 }}>Finanzplanung</span>
                                            </div>
                                            <div>
                                                <div style={{ fontWeight: 300, fontSize: '14px', wordSpacing: '-1px' }}>Unabhängig, ob Sie in Aktien oder Immobilien investieren, nutzen Sie unsere Steuerberatung, um steuerliche Vorteile zu geniessen.</div>
                                            </div>
                                        </div>
                                    </HashLink>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <HashLink to="/Privatkunden/Finanzen#Hypotheke" className='text-decoration-none'>
                                        <div className="sliderCards p-2 p-md-4 h-100">
                                            <div className='pb-3 '>
                                                <img src={Hypotheken} alt="" />
                                            </div>
                                            <div className='pb-2'>
                                                <span style={{ fontWeight: 600 }}>Hypotheken</span>
                                            </div>
                                            <div>
                                                <div style={{ fontWeight: 300, fontSize: '14px', wordSpacing: '-1px' }}>Gerne unterstützen wir Sie bei der Auswahl der geeigneter Hypothek.</div>
                                            </div>
                                        </div>
                                    </HashLink>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 ">
                                    <HashLink to="/Privatkunden/Versicherungen#Krankenversicherung" className='text-decoration-none'>
                                        <div className="sliderCards p-2 p-md-4 h-100">
                                            <div className='pb-3 '>
                                                <img src={Versicherungen} alt="" />
                                            </div>
                                            <div className='pb-2'>
                                                <span style={{ fontWeight: 600 }}>Versicherungen</span>
                                            </div>
                                            <div>
                                                <div style={{ fontWeight: 300, fontSize: '14px', wordSpacing: '-1px' }}>Wir kennen die Versicherungsunternehmen und Ihre Policen.
                                                    Durch einen Vergleich lassen sich Prämien einsparen und die Bedingungen optimieren.</div>
                                            </div>
                                        </div>
                                    </HashLink>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <HashLink to="/Privatkunden/Finanzen#Steuerberatung" className='text-decoration-none'>
                                        <div className="sliderCards p-2 p-md-4 h-100">
                                            <div className='pb-3 '>
                                                <img src={Steuerberatung} alt="" />
                                            </div>
                                            <div className='pb-2'>
                                                <span style={{ fontWeight: 600 }}>Steuerberatung</span>
                                            </div>
                                            <div>
                                                <div style={{ fontWeight: 300, fontSize: '14px', wordSpacing: '-1px' }}>Steuerberatung gehört zu unseren Kernkompetenzen, wir beraten Sie kompetent und kostengünstig.</div>
                                            </div>
                                        </div>
                                    </HashLink>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4">
                                    <HashLink to="/Geschaftskunden" className='text-decoration-none'>
                                        <div className="sliderCards p-2 p-md-4 h-100">
                                            <div className='pb-3 '>
                                                <img src={KMU} alt="" />
                                            </div>
                                            <div className='pb-2'>
                                                <span style={{ fontWeight: 600 }}>KMU</span>
                                            </div>
                                            <div>
                                                <div style={{ fontWeight: 300, fontSize: '14px', wordSpacing: '-1px' }}>Gerne unterstützen wir Sie bei der Auswahl geeigneter Geschäftsversicherungen.</div>
                                            </div>
                                        </div>
                                    </HashLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBlueBgSection