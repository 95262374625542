import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// import Stars from '../../assets/images/stars.svg'
import { Link } from 'react-router-dom'

function HomeSlider() {
    return (
        <div>
            <div className="">
                <div className='blueBgDiv pt-3'>

                </div>
            </div>
            <div className=''>
                <div className='text-center py-5 responsiveTextMobile'>
                    <div className='pt-5 mt-5'>
                        <div className=''>
                            <span className='fs-3' style={{ fontWeight: 700 }}>Schließen Sie sich unseren Bewertungen an</span>
                        </div>
                    </div>
                    <div className='pb-0 pb-md-5'>
                        <span style={{ fontWeight: 400 }}>Hier ist, warum uns die Leute lieben</span>
                    </div>
                </div>
                <div className='pb-5 ps-4 ps-lg-5 pe-0 pe-md-4 pe-lg-5' style={{ backgroundColor: '#F6F8FE' }}>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1.1}
                        loop={false}
                        centerInsufficientSlides={true}
                        breakpoints={{

                            768: {
                                slidesPerView: 2.2,
                                spaceBetween: 10,
                            },
                            991: {
                                slidesPerView: 3.1,
                                spaceBetween: 10,
                            },
                            1300: {
                                slidesPerView: 4.1,
                                spaceBetween: 10,

                            },
                            // 1250: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 25,
                            // },
                            1400: {
                                slidesPerView: 3.5,
                                spaceBetween: 30,

                            },
                            1600: {
                                slidesPerView: 4.1,
                                spaceBetween: 30,
                            },
                        }}
                    >

                        <SwiperSlide>
                            <div>
                                <div className='sliderCards text-start p-4'>

                                    <div>
                                        <div className="row g-0">
                                            <div className="col">
                                                <div>
                                                    <span style={{ fontWeight: 700, color: '#3A5274' }}>
                                                        Sarah Reinfelden
                                                    </span>
                                                </div>
                                                {/* <div>
                                                    <span style={{ fontSize: '14px', color: 'rgba(60, 60, 67, 0.5)' }}>
                                                        Ein Jahr bei uns
                                                    </span>
                                                </div> */}
                                            </div>
                                            <div className="col-auto my-auto">
                                                <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.6875 10.25V22.5H0V10.075C0 1.675 8.15625 0.625 8.15625 0.625L9.24375 3.075C9.24375 3.075 5.61875 3.6 4.89375 6.4C4.16875 8.5 5.61875 10.25 5.61875 10.25H12.6875Z" fill="#3A5274" />
                                                    <path d="M29 10.25V22.5H16.3125V10.075C16.3125 1.675 24.4688 0.625 24.4688 0.625L25.5563 3.075C25.5563 3.075 21.9312 3.6 21.2062 6.4C20.4812 8.5 21.9313 10.25 21.9313 10.25H29Z" fill="#3A5274" />
                                                </svg>

                                            </div>
                                        </div>

                                        <div className='pt-3'>
                                            <span style={{ fontSize: '14px' }}>
                                                Eifach top! De Berater het mi super berate und mir en Versicherig gfunge, wo genau zu mir passt.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <div className='sliderCards text-start p-4'>

                                    <div>
                                        <div className="row g-0">
                                            <div className="col">
                                                <div>
                                                    <span style={{ fontWeight: 700, color: '#3A5274' }}>
                                                        Anna Schärer
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: '14px', color: 'rgba(60, 60, 67, 0.5)' }}>
                                                        Zwei Jahre bei uns
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-auto my-auto">
                                                <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.6875 10.25V22.5H0V10.075C0 1.675 8.15625 0.625 8.15625 0.625L9.24375 3.075C9.24375 3.075 5.61875 3.6 4.89375 6.4C4.16875 8.5 5.61875 10.25 5.61875 10.25H12.6875Z" fill="#3A5274" />
                                                    <path d="M29 10.25V22.5H16.3125V10.075C16.3125 1.675 24.4688 0.625 24.4688 0.625L25.5563 3.075C25.5563 3.075 21.9312 3.6 21.2062 6.4C20.4812 8.5 21.9313 10.25 21.9313 10.25H29Z" fill="#3A5274" />
                                                </svg>

                                            </div>
                                        </div>

                                        <div className='pt-3'>
                                            <span style={{ fontSize: '14px' }}>
                                                Ich ha mi no nie so guet über Versicherige informiert gfühlt wie nach dere Beratig. Absolut empfehlenswert!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <div className='sliderCards text-start p-4'>

                                    <div>
                                        <div className="row g-0">
                                            <div className="col">
                                                <div>
                                                    <span style={{ fontWeight: 700, color: '#3A5274' }}>
                                                        Lukas Moser
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: '14px', color: 'rgba(60, 60, 67, 0.5)' }}>
                                                        Ein Jahr bei uns
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-auto my-auto">
                                                <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.6875 10.25V22.5H0V10.075C0 1.675 8.15625 0.625 8.15625 0.625L9.24375 3.075C9.24375 3.075 5.61875 3.6 4.89375 6.4C4.16875 8.5 5.61875 10.25 5.61875 10.25H12.6875Z" fill="#3A5274" />
                                                    <path d="M29 10.25V22.5H16.3125V10.075C16.3125 1.675 24.4688 0.625 24.4688 0.625L25.5563 3.075C25.5563 3.075 21.9312 3.6 21.2062 6.4C20.4812 8.5 21.9313 10.25 21.9313 10.25H29Z" fill="#3A5274" />
                                                </svg>

                                            </div>
                                        </div>

                                        <div className='pt-3'>
                                            <span style={{ fontSize: '14px' }}>
                                                Der Berater war äusserst kompetent und hat mir viel Geld gespart. Ich kann ihn nur weiterempfehlen!
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <div className='sliderCards text-start p-4'>

                                    <div>
                                        <div className="row g-0">
                                            <div className="col">
                                                <div>
                                                    <span style={{ fontWeight: 700, color: '#3A5274' }}>
                                                        Melanie Baumann
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: '14px', color: 'rgba(60, 60, 67, 0.5)' }}>
                                                        Ein Jahr bei uns
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-auto my-auto">
                                                <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.6875 10.25V22.5H0V10.075C0 1.675 8.15625 0.625 8.15625 0.625L9.24375 3.075C9.24375 3.075 5.61875 3.6 4.89375 6.4C4.16875 8.5 5.61875 10.25 5.61875 10.25H12.6875Z" fill="#3A5274" />
                                                    <path d="M29 10.25V22.5H16.3125V10.075C16.3125 1.675 24.4688 0.625 24.4688 0.625L25.5563 3.075C25.5563 3.075 21.9312 3.6 21.2062 6.4C20.4812 8.5 21.9313 10.25 21.9313 10.25H29Z" fill="#3A5274" />
                                                </svg>

                                            </div>
                                        </div>

                                        <div className='pt-3'>
                                            <span style={{ fontSize: '14px' }}>
                                                Ich war sehr zufrieden mit der Beratung durch den Versicherungsbroker. Er hat sich Zeit genommen, um meine Fragen zu beantworten und mir die besten Optionen aufgezeigt.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <div className='sliderCards text-start p-4 me-4 me-md-0'>

                                    <div>
                                        <div className="row g-0">
                                            <div className="col">
                                                <div>
                                                    <span style={{ fontWeight: 700, color: '#3A5274' }}>
                                                        Abduhl Aziz
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: '14px', color: 'rgba(60, 60, 67, 0.5)' }}>
                                                        Ein Jahr bei uns
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-auto my-auto">
                                                <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.6875 10.25V22.5H0V10.075C0 1.675 8.15625 0.625 8.15625 0.625L9.24375 3.075C9.24375 3.075 5.61875 3.6 4.89375 6.4C4.16875 8.5 5.61875 10.25 5.61875 10.25H12.6875Z" fill="#3A5274" />
                                                    <path d="M29 10.25V22.5H16.3125V10.075C16.3125 1.675 24.4688 0.625 24.4688 0.625L25.5563 3.075C25.5563 3.075 21.9312 3.6 21.2062 6.4C20.4812 8.5 21.9313 10.25 21.9313 10.25H29Z" fill="#3A5274" />
                                                </svg>

                                            </div>
                                        </div>

                                        <div className='pt-3'>
                                            <span style={{ fontSize: '14px' }}>
                                                Ich hatte schon lange keine so unkomplizierte und schnelle Abwicklung einer Versicherungsangelegenheit. DL Finance hat das sehr professionell und effizient erledigt.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className='text-center pb-5' style={{ backgroundColor: '#F6F8FE' }}>
                    <Link to='/Angebot'>
                        <button className='homeFirstSection py-2'>
                            Angebot einholen
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default HomeSlider