import React from 'react'
import OldLady from '../../assets/images/business/oldLady.svg'
import Scope from '../../assets/images/business/scope.svg'
import Coin from '../../assets/images/business/peopleCoins.svg'
import { Link } from 'react-router-dom'

function ThirdSection(props) {
    return (
        <div>
            <div className="businessLtBlueBg">
                <div className="container-lg px-4 px-md-5 px-lg-0">
                    <div className="py-0 py-lg-5 mt-5">
                        <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto">
                                <div className="pb-4">
                                    <span className='businessSecondTitle fs-3'>Berufliche Vorsorge (BVG)</span>
                                </div>
                                <div className="pb-4 pb-sm-3">
                                    <span>Ein weiterer Punkt betrifft die Pensionskasse, die Sie sorgfältig auswählen sollten.  </span>
                                </div>
                                {(props.screenWidth > 576.98) && (
                                    <>
                                        <div className="pb-3">
                                            Die 2. Säule des schweizerischen Sozialsystems gilt als die sogenannte Pensionskasse und trägt dazu bei, dass nach der Pensionierung die Fortsetzung der gewohnten Lebenshaltung in angemessener Weise ermöglicht wird.

                                        </div>
                                        <div className="pb-3">
                                            Die 2. Säule ergänzt die Basisleistungen der existenzsichernden Renten aus der ersten Säule, der AHV.

                                        </div>
                                        <div className="pb-4">
                                            Wir helfen Ihnen bei der Suche nach der optimalen Vorsorgevariante.
                                        </div>

                                    </>
                                )}
                                <div className="">
                                    {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto">
                                <div className='text-center'>
                                    <img src={OldLady} className="businessImgs" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-5 my-5">
                        <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center pb-md-5 pb-0">
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto order-2 order-md-1">
                                <div className='text-center'>
                                    <img src={Scope} className="businessImgs" alt="" />
                                </div>
                            </div>

                            <div className="col-12 col-md-5 col-lg-5 my-md-auto order-1 order-md-2">
                                <div className="pb-4">
                                    <span className='businessSecondTitle fs-3'>Sach- und Vermögensversicherung</span>
                                </div>
                                {(props.screenWidth > 576.98) && (
                                    <>
                                        <div className="pb-3">
                                            <span>Nicht immer läuft alles so wie es soll.</span>
                                        </div>
                                        <div className="pb-3">
                                            Auch Sach- und Rechtsschutzversicherungen sind zu bedenken, um im Schadensfall gegen einen materiellen Verlust oder bei einem Rechtsstreit abgesichert zu sein.
                                        </div>
                                    </>
                                )}
                                <div className="pb-4">
                                    Mit unserer Hilfe versichern Sie ihre Besitztümer (Gebäude, Sachgegenstände, Haushalt, Fahrzeuge etc.) richtig.
                                </div>

                                <div className="pb-4">
                                    {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                                </div>
                                <div className="">
                                    <Link to='/Angebot'>
                                        <button className='homeFirstSection py-2'>
                                            Angebot einholen
                                        </button>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg px-4 px-md-5 px-lg-0 pb-5">
                <div className="py-5">
                    <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto">
                            <div className="pb-4">
                                <span className='businessSecondTitle fs-3'>Krankentaggeld (KTG)</span>
                            </div>

                            <div className="pb-4 pb-sm-3">
                                <span>Im Krankheitsfall oder während des Mutterschaftsurlaubs erhalten Mitarbeitende für eine gewisse Zeit ihren Lohn, obschon sie am Arbeitsplatz fehlen. So schreibt es das Gesetz vor. </span>
                            </div>
                            {(props.screenWidth > 576.98) && (
                                <>
                                    <div className="pb-4">
                                        Gegen diese Kosten kann sich der Arbeitgeber mit der KTG absichern. Eine KTG Versicherung ist freiwillig, aber sehr zu empfehlen, da Sie das Unternehmen bei längeren Krankheitsfällen vor hohen Kosten schützt.
                                    </div>
                                </>
                            )}
                            <div className="pb-4">
                                {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                            </div>
                            <div className="">
                                <Link to='/Angebot'>
                                    <button className='homeFirstSection py-2'>
                                        Angebot einholen
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto">
                            <div className='text-center'>
                                <img src={Coin} className="businessImgs" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ThirdSection