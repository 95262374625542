import React from 'react'
import HomeFirstSection from './homeComponents/HomeFirstSection'
import HomeSlider from './homeComponents/HomeSlider'
import HomeBlueBgSection from './homeComponents/HomeBlueBgSection'
import HomeLastComponent from './homeComponents/HomeLastComponent'
import '../assets/css/Home.css'
function Home() {
    return (
        <div>
            <div className='pb-5 mb-5'>
                <HomeFirstSection />
            </div>
            <div className=''>
                <HomeSlider />
            </div>
            <div>
                <HomeBlueBgSection />
            </div>
            <div className='py-5 mt-3'>
                <HomeLastComponent />
            </div>
            
        </div>
    )
}

export default Home