import React from 'react'

function Clouds() {
    return (
        <div>
            <div className="px-4 px-lg-5">
                <div className="cloudsDivB text-center ">
                    <div className='pb-2 pt-5'>
                        <span className='homeFirstTitle'>
                            Geschäftskunden
                        </span>
                    </div>
                    {/* <div>
                        <span className='homeSecondTitle'>
                            Verwenden Sie das Menü auf der linken Seite um sich über die verschiedenen Dienstleistungen, die zu einer <br></br> Finanzberatung bei DL Finance zu informieren.
                        </span>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Clouds