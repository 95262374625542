import React from 'react'
import Vorsorge from '../../assets/images/Private/vorsorge.svg'
import Lucken from '../../assets/images/Private/lucken.svg'
import { Link } from 'react-router-dom'

function ThirdSection(props) {
    return (
        <div>
            <div className="container-lg px-4 px-md-5 px-lg-0">
                <div className="mt-5" id='Vorsorgeberatung'>
                    <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto order-2 order-md-1">
                            <div className='text-center'>
                                <img src={Vorsorge} className="businessImgs" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto order-1 order-md-2">
                            <div className="pb-4">
                                <span className='businessSecondTitle fs-3'>Vorsorgeberatung</span>
                            </div>
                            <div className="pb-3">
                                <span>Das Drei-Säulen-System bildet die Basis der Schweizer Vorsorge.
                                </span>
                            </div>
                            <div className="pb-4 pb-sm-3">
                                Die 3. Säule ist freiwillig und dient zur Ergänzung der beiden ersten Säulen. Sie gliedert sich in die gebundene Vorsorge (3a) und in die freie Vorsorge (3b), wobei 3a bei der Steuer abzugsfähig ist, 3b nicht.

                            </div>
                            {(props.screenWidth > 576.98) && (
                                <>
                                    <div className="pb-3">
                                        Die Rente aus AHV und Pensionskasse deckt in der Regel 50 bis 60 % des letzten Erwerbseinkommen.

                                    </div>
                                    <div className="pb-4">
                                        Eine Versorgungslücke entsteht, wenn die Pension oder Rente nicht mehr den finanziellen Bedarf im Ruhestand deckt.

                                    </div>
                                </>
                            )}
                            <div className="pb-4">
                                {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                            </div>
                            <div>
                                <Link to='/Angebot'>
                                    <button className='homeFirstSection py-2'>
                                        Angebot einholen
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-5 my-5">
                    <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto order-1">
                            <div className="pb-4">
                                <span className='businessSecondTitle fs-3'>Lückenrechner</span>
                            </div>
                            <div className="pb-3">
                                <span>Um eine Vorsorgelücke zu berechnen, braucht es folgende Daten: Geschlecht, Jahrgang, Jahreslohn, der Bedarf für die Lebenshaltungskosten prozentual vom Lohn, das bereits angesparte Altersvorsorge-Vermögen sowie die zu erwartende jährliche Rente aus der Pensionskasse. </span>
                            </div>
                            {(props.screenWidth > 576.98) && (
                                <>
                                    <div className="pb-3">
                                        Aus diesen Angaben ermittelt der Rechner die Rentenlücke.
                                    </div>
                                    <div className="pb-4">
                                        Der nächste Schritt zeigt, wie viel Prozent Ihres Lohnes Sie zurücklegen müssen, um den künftigen Bedarf zu decken.
                                    </div>
                                </>
                            )}
                            <div className="pb-4">
                                {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                            </div>
                            <div>
                                <Link to='/Angebot'>
                                    <button className='homeFirstSection py-2'>
                                        Angebot einholen
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto order-2 order-md-1">
                            <div className='text-center'>
                                <img src={Lucken} className="businessImgs" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThirdSection