import React from 'react'
import Deal from '../../assets/images/business/businessDeal.svg'
import Accident from '../../assets/images/business/accident.svg'
function SecondSection(props) {
    return (
        <div>
            <div className="container-lg px-4 px-md-5 px-lg-0">
                <div className="pt-0 pt-lg-5">
                    <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto">
                            <div className="pb-4 pb-md-5">
                                <span className='businessSecondTitle fs-3'>Geschäftsversicherungen</span>
                            </div>
                            {(props.screenWidth > 576.98) && (
                                <>
                                    <div className="pb-3">
                                        <span>Unabhängig ob Sie einen Einmannbetrieb oder ein kleines oder mittleres Unternehmen (KMU) betreiben, sind Geschäftsversicherungen Bestandteil Ihres Unternehmens.  </span>
                                    </div>
                                    <div className="pb-3">
                                        Mit gut gewählten Geschäftsversicherungen können Sie Ihre Betriebskosten senken.

                                    </div>
                                    <div className="pb-3">
                                        Auch eine Betriebsgründung erfordert eine umfassende Budgetplanung zur Ermittlung des künftigen Zahlungsmittelbedarfs. Start-up-Unternehmen stehen in der Pflicht, sich um Sozialversicherungen für sich selbst und für ihre Mitarbeitenden zu kümmern.

                                    </div>
                                    <div className="pb-4 pb-md-5">
                                        Gerne unterstützen wir Sie bei der Auswahl geeigneter Geschäftsversicherungen.

                                    </div>
                                </>
                            )}
                            {(props.screenWidth < 576.98) && (
                                <div className='pb-4'>
                                    Unabhängig ob Sie einen Einmannbetrieb oder ein kleines oder mittleres Unternehmen (KMU) betreiben, sind Geschäftsversicherungen Bestandteil Ihres Unternehmens.
                                </div>
                            )}

                            <div className="">
                                {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto">
                            <div className='text-center'>
                                <img src={Deal} className="businessImgs" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container-lg px-4 px-md-5 px-lg-0 py-5 my-5">
                <div className="pt-0 pt-lg-5 pb-5">
                    <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto order-2 order-md-1">
                            <div className='text-center'>
                                <img src={Accident} className="businessImgs" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto order-1 order-md-2">
                            <div className="pb-4">
                                <span className='businessSecondTitle fs-3'>Unfallversicherung (UVG)</span>
                            </div>
                            {(props.screenWidth > 576.98) && (
                                <>
                                    <div className="pb-3">
                                        <span>Das Unfallversicherungsgesetz ist obligatorisch und versichert die Arbeitnehmer gegen wirtschaftliche Folgen von Unfällen, unfallähnlichen Körperschädigungen und Berufskrankheiten.  </span>
                                    </div>
                                    <div className="pb-4">
                                        Sichern Sie sich und Ihre Mitarbeiter optimal gegen Unfälle in Ihrem Unternehmen und in der Freizeit ab. Unser Fachspezialist unterstützt Sie Ihren Betrieb richtig zu versichern.
                                    </div>
                                </>
                            )}
                            {(props.screenWidth < 576.98) && (
                                <>
                                    <div className="pb-4">
                                        Das Unfallversicherungsgesetz ist obligatorisch und versichert die Arbeitnehmer gegen wirtschaftliche Folgen von Unfällen, unfallähnlichen Körperschädigungen und Berufskrankheiten.
                                    </div>
                                </>
                            )}
                            <div className="">
                                {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondSection