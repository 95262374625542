import React from 'react'
import Dot from '../../assets/images/dlf/greenDot.svg'
import GDot from '../../assets/images/dlf/greyDot.svg'
import Cost from '../../assets/images/dlf/cost.svg'
import Time from '../../assets/images/dlf/time.svg'
import Flex from '../../assets/images/dlf/flexible.svg'
function Categories() {
    return (
        <div>
            <div className='text-center container-lg px-4 px-md-5'>
                <div className="row g-0 pb-4 justify-content-center">
                    <div className="col-12 col-xxl-9">
                        <div className="row g-5 pb-5 justify-content-center">
                            <div className="col-12 col-sm-auto">
                                <div className="pb-3">
                                    <img className='dlfimages' src={Flex} alt="" />
                                </div>
                                <div className='pb-5'>
                                    <span style={{ fontWeight: 500 }}>FLEXIBEL</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-auto">
                                <div className="pb-3">
                                    <img className='dlfimages' src={Time} alt="" />
                                </div>
                                <div className='pb-5'>
                                    <span style={{ fontWeight: 500 }}>ZEITERSPARNIS</span>
                                </div>
                            </div>
                            <div className="col-12 col-sm-auto">
                                <div className="pb-3">
                                    <img className='dlfimages' src={Cost} alt="" />
                                </div>
                                <div className='pb-5'>
                                    <span style={{ fontWeight: 500 }}>KOSTENERSPARNIS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blueBgBDlf">
                <div className='text-center container-lg px-5 px-md-5 pb-5'>
                    <div className="row g-4 justify-content-center">
                        <div className="col-12 col-sm-6 col-md-auto">
                            <div className="cardBg">
                                <div className='px-3 pb-4 w-100'>
                                    <div className="row g-2">
                                        <div className="col-auto">
                                            <svg width="34" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M37.648 10.8659L30.4201 0.974416C30.0471 0.464803 29.4136 0.226626 28.8041 0.358055L4.50613 5.59715C3.88897 5.73023 3.40997 6.20826 3.28773 6.82466L0.726139 19.0183C0.727782 19.0259 0.516031 19.8929 1.22891 20.5365L23.3121 39.9347C24.5077 40.857 25.5877 39.9863 25.8112 39.3959L37.8633 12.789C38.1165 12.4473 38.3021 11.5701 37.648 10.8659ZM33.7628 10.9302L25.3968 12.7341L25.2152 4.38509L28.461 3.68522L33.7628 10.9302ZM17.1448 17.7665L22.2955 16.656L22.5952 30.545L17.1448 17.7665ZM15.8726 14.7877L12.5976 7.10567L22.0456 5.06852L22.2271 13.4175L15.8726 14.7877ZM6.18217 8.48897L9.42799 7.7891L12.703 15.4711L4.33701 17.275L6.18217 8.48897ZM13.9752 18.45L20.1496 32.9222L5.70065 20.2341L13.9752 18.45ZM25.8047 31.7108L25.4668 15.9801L33.7413 14.196L25.8047 31.7108Z" fill="#CACACA" />
                                            </svg>
                                        </div>
                                        <div className="col mt-auto">
                                            <div style={{ fontWeight: 600, fontSize: "17px" }} className='text-start'>
                                                SILVER
                                            </div>
                                        </div>
                                        <div className="col mt-auto">
                                            <div style={{ fontWeight: 600, fontSize: "20px" }}>
                                                69 CHF
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="underCard">
                                <div className="pt-4" style={{ borderBottom: '1px solid #000' }}>
                                </div>
                                <div className="p-4 text-start">
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Gesamtberatung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Notfallblatt</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Ordner für Finanzen</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>24/7 Support via Chatbot/</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Mail</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Listenelement</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Jährliche Optimierung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>myBudget M</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Budgetanalyse S</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={GDot}  alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>VIP Telefonsupport</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={GDot}  alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Steuerberatung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={GDot}  alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Vermögensberatung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={GDot}  alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Marktanalysen</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img  src={GDot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Pensionsplanung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-4 text-center">
                                        <button className='homeFirstSection py-2 px-5'>
                                            Anfragen
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-auto">

                            <div className="cardBg">
                                <div className='px-3 pb-4 w-100'>
                                    <div className="row g-2">
                                        <div className="col-auto">
                                            <svg width="34" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M37.648 10.8659L30.4201 0.974416C30.0471 0.464803 29.4136 0.226626 28.8041 0.358055L4.50613 5.59715C3.88897 5.73023 3.40997 6.20826 3.28773 6.82466L0.726139 19.0183C0.727782 19.0259 0.516031 19.8929 1.22891 20.5365L23.3121 39.9347C24.5077 40.857 25.5877 39.9863 25.8112 39.3959L37.8633 12.789C38.1165 12.4473 38.3021 11.5701 37.648 10.8659ZM33.7628 10.9302L25.3968 12.7341L25.2152 4.38509L28.461 3.68522L33.7628 10.9302ZM17.1448 17.7665L22.2955 16.656L22.5952 30.545L17.1448 17.7665ZM15.8726 14.7877L12.5976 7.10567L22.0456 5.06852L22.2271 13.4175L15.8726 14.7877ZM6.18217 8.48897L9.42799 7.7891L12.703 15.4711L4.33701 17.275L6.18217 8.48897ZM13.9752 18.45L20.1496 32.9222L5.70065 20.2341L13.9752 18.45ZM25.8047 31.7108L25.4668 15.9801L33.7413 14.196L25.8047 31.7108Z" fill="#F6C451" />
                                            </svg>

                                        </div>
                                        <div className="col mt-auto">
                                            <div style={{ fontWeight: 600, fontSize: "17px" }} className='text-start'>
                                                GOLD
                                            </div>
                                        </div>
                                        <div className="col mt-auto">
                                            <div style={{ fontWeight: 600, fontSize: "20px" }}>
                                                365 CHF
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="underCard">
                                <div className="pt-4" style={{ borderBottom: '1px solid #000' }}>
                                </div>
                                <div className="p-4 text-start">
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Gesamtberatung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Notfallblatt</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Ordner für Finanzen</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>24/7 Support via Chatbot/</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Mail</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Listenelement</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Jährliche Optimierung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>myBudget M</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Budgetanalyse S</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>VIP Telefonsupport</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Steuerberatung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={GDot}  alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Vermögensberatung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={GDot}  alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Marktanalysen</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img  src={GDot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Pensionsplanung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-4 text-center">
                                        <button className='homeFirstSection py-2 px-5'>
                                            Anfragen
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-auto">

                            <div className="cardBg">
                                <div className='px-3 pb-4 w-100'>
                                    <div className="row g-2">
                                        <div className="col-auto">
                                            <svg width="34" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M37.648 10.8659L30.4201 0.974416C30.0471 0.464803 29.4136 0.226626 28.8041 0.358055L4.50613 5.59715C3.88897 5.73023 3.40997 6.20826 3.28773 6.82466L0.726139 19.0183C0.727782 19.0259 0.516031 19.8929 1.22891 20.5365L23.3121 39.9347C24.5077 40.857 25.5877 39.9863 25.8112 39.3959L37.8633 12.789C38.1165 12.4473 38.3021 11.5701 37.648 10.8659ZM33.7628 10.9302L25.3968 12.7341L25.2152 4.38509L28.461 3.68522L33.7628 10.9302ZM17.1448 17.7665L22.2955 16.656L22.5952 30.545L17.1448 17.7665ZM15.8726 14.7877L12.5976 7.10567L22.0456 5.06852L22.2271 13.4175L15.8726 14.7877ZM6.18217 8.48897L9.42799 7.7891L12.703 15.4711L4.33701 17.275L6.18217 8.48897ZM13.9752 18.45L20.1496 32.9222L5.70065 20.2341L13.9752 18.45ZM25.8047 31.7108L25.4668 15.9801L33.7413 14.196L25.8047 31.7108Z" fill="#3F3BE1" />
                                            </svg>
                                        </div>
                                        <div className="col mt-auto">
                                            <div style={{ fontWeight: 600, fontSize: "17px" }} className='text-start'>
                                                PLATINUM
                                            </div>
                                        </div>
                                        <div className="col mt-auto">
                                            <div style={{ fontWeight: 600, fontSize: "20px" }}>
                                                549 CHF
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="underCard">
                                <div className="pt-4" style={{ borderBottom: '1px solid #000' }}>
                                </div>
                                <div className="p-4 text-start">
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Gesamtberatung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Notfallblatt</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Ordner für Finanzen</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>24/7 Support via Chatbot/</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Mail</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Listenelement</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Jährliche Optimierung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>myBudget M</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Budgetanalyse S</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>VIP Telefonsupport</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Steuerberatung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Vermögensberatung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Marktanalysen</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row g-2">
                                            <div className="col-auto my-auto">
                                                <img src={Dot} alt="" />
                                            </div>
                                            <div className="col my-auto">
                                                <div >
                                                    <span>Pensionsplanung</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-4 text-center">
                                        <button className='homeFirstSection py-2 px-5'>
                                            Anfragen
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Categories