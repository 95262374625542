import React from 'react'
import { useEffect, useState } from 'react'
import Clouds from './private/Clouds'
import SecondSection from './private/SecondSection'
import '../assets/css/Private.css'

function PrivateKunden() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])
  return (
    <div className='px-2 px-sm-0'>
      <Clouds screenWidth={screenWidth} />
      <SecondSection screenWidth={screenWidth} />
      
    </div>
  )
}

export default PrivateKunden