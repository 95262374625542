import React from 'react'
import Woman from '../../assets/images/womanJumping.svg'
import WomanDesktop from '../../assets/images/womanDesktop.svg'
import CloudPhone from '../../assets/images/cloudPhone.svg'
import ButtonPhoto from '../../assets/images/buttonPhoto.svg'
import { useEffect, useState } from "react"

function HomeLastComponent() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])
    return (
        <div>
            <div className='py-0 py-md-5 my-0 mt-md-5'>
                <div className='position-relative text-center px-4 px-lg-5 py-5 col-12 col-md-12 col-lg-6 col-xl-6 col-xxl-5 me-auto ms-0 ms-lg-5'>
                    <div className='ms-0 ms-lg-5 ps-0 ps-lg-5'>
                        <div className='pb-3'>
                            <span style={{ fontWeight: 700 }} className='fs-4'>Wieso sollten Sie auf einen Broker wie die DL Finance GmbH vertrauen.</span>
                        </div>
                        <div className='pb-3'>
                            <span style={{ fontWeight: 400, fontSize: '14px' }}>
                                Wir besitzen Erfahrung, Professionalität und Engagement.
                                Bei Beratung und Umsetzung handeln wir zukunftsorientiert, denken weiter und beschreiten voll Zuversicht neue Wege.
                                Wir legen grossen Wert auf eine langjährige, vertrauensvolle Zusammenarbeit mit unseren Kunden.
                            </span>
                        </div>
                        <div>
                            <a href="/" style={{ color: '#2F60DC', fontWeight: 600 }}>Erfahre mehr über unsere Firmengeschichte</a>
                        </div>
                    </div>
                </div>
                <div className='py-5 mb-5'>
                    {(screenWidth < 991.98) && (
                        <img className='img-fluid' src={Woman} alt="" />
                    )}
                    {(screenWidth > 991.98) && (
                        <img className='img-fluid imgMarginTop' src={WomanDesktop} alt="" />
                    )}
                </div>
                <div className="col-12 col-md-12 col-lg-10 col-xl-9 mx-auto">
                    <div className="row g-0 pt-0 pt-md-5 justify-content-between">
                        <div className="col-12 col-md-4 my-auto ps-4 text-center">
                            <img src={CloudPhone} className="cloudPhoto" alt="" />
                        </div>
                        <div className="col-12 col-md-6">
                            <div className={`px-4 ${(screenWidth > 767.98) ? 'text-start' : 'text-center'} `}>
                                <div className='pb-2 pt-5 mt-5 pt-md-0'>
                                    <span className='fs-3' style={{ fontWeight: 600 }}>Hier, wenn Sie uns brauchen</span>
                                </div>
                                {/* <div className='pb-3'>
                                    <span style={{ fontWeight: 400, fontSize: '14px' }}>is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </span>
                                </div> */}
                                <div className='infoDiv p-3 d-none d-md-block mb-4 mt-2'>
                                    <div className="row g-0">
                                        <div className="col-auto me-3">
                                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5Z" stroke="black" />
                                                <line x1="6.5" y1="5.5" x2="6.5" y2="9.5" stroke="black" strokeLinecap="round" />
                                                <circle cx="6.5" cy="3.5" r="0.5" fill="black" />
                                            </svg>

                                        </div>
                                        <div className="col">
                                            <span className=''>
                                            Wir haben derzeit ein aussergewöhnlich hohes Aufkommen an Anfragen. Wenn Sie uns kontaktieren, haben Sie bitte etwas Geduld. Wir werden uns so schnell wie möglich bei Ihnen melden.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='pb-3'>
                                    <button className='lastSectionBtn '>

                                        <div className="row g-0 justify-content-center">
                                            <div className="col-auto pe-3">
                                                <img src={ButtonPhoto} alt="" />
                                            </div>
                                            <div className="col-auto my-auto text-end">
                                                <div>
                                                    <span>Jetzt Rückruf anfragen</span>
                                                </div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div>
                                    <span style={{ color: '#2F60DC', fontWeight: 600, fontSize: '14px' }}>Langsamere Online-Reaktionszeiten</span>
                                </div>
                                {/* <div className='pt-5 d-none d-lg-block' style={{ color: '#948E8E', fontWeight: 500, fontSize: '14px' }}>
                                    <div>
                                        <span>Online hours</span>
                                    </div>
                                    <div>
                                        <hr style={{ background: '#D8D5D5', opacity: 1 }} className='my-2' />
                                    </div>
                                    <div>
                                        <div className="row g-0">
                                            <div className="col-6">
                                                <span>Mo-Fr</span>
                                            </div>
                                            <div className="col">
                                                <span>9am- 5pm</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeLastComponent