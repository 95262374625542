import React from 'react'
import { useEffect, useState } from 'react'
import FifthSection from './private/FifthSection'
import FourthSection from './private/FourthSection'
import ThirdSection from './private/ThirdSection'
import Clouds from './private2/Clouds'

function PrivateFinanzen() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])
    return (
        <div>
            <Clouds screenWidth={screenWidth} />
            <ThirdSection screenWidth={screenWidth} />
            <FourthSection screenWidth={screenWidth} />
            <FifthSection screenWidth={screenWidth} />
        </div>
    )
}

export default PrivateFinanzen