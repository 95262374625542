import React from 'react'

function Title() {
    return (
        <div>
            <div className='px-4 px-lg-5 pb-0 pb-sm-5 mb-5'>
                <div className="cloudsDiv text-center ">
                    <div className='pb-2 pt-5'>
                        <span className='homeFirstTitle'>
                        DL Abonnement
                        </span>
                    </div>
                    <div>
                        <span className='homeSecondTitle'>
                        Wir von DL Finance erleichtert Ihnen mit unseren Abonnenten Ihre Finanzen. Gemeinsam mit einem <br></br> Berater von DL Finance, bestimmen Sie, welches Abo am besten zu Ihrer Lebenssituation passt.

                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Title