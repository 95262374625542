import React from 'react'
import { useState,useEffect } from 'react'
import '../assets/css/Business.css'
import Clouds from './business/Clouds.js'
import SecondSection from './business/SecondSection'
import ThirdSection from './business/ThirdSection'
function Business() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])
    return (
        <div className='px-2 px-sm-0'>
            <div className="pb-5 mb-5">
                <Clouds />
            </div>
            <div className="">
                <SecondSection screenWidth={screenWidth} />
            </div>
            <div className="">
                <ThirdSection screenWidth={screenWidth} />
            </div>
        </div>
    )
}

export default Business