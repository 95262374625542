import React from 'react'



function Clouds(props) {
    return (
        <div>
            <div className="px-4 px-lg-5">
                <div className="cloudsDivB text-center mb-5 pb-5">
                    <div className='pb-2 pt-5'>
                        <span className='homeFirstTitle'>
                            Versicherungen
                        </span>
                    </div>
                    <div>
                        <span className='homeSecondTitle'>
                            Wir kennen die Versicherungsunternehmen und Ihre Policen.<br></br> Durch einen Vergleich lassen sich Prämien einsparen und die Bedingungen optimieren.
                        </span>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Clouds