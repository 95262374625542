
import './App.css';
import Header from './Header.js';
import Home from './components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from './components/About';
import Footer from './components/homeComponents/Footer';
import Business from './components/Business';
import PrivateKunden from './components/PrivateKunden';
import DLF from './components/Dlf';
import ScrollTop from "./ScrollTop.js"
import Angebot from './components/Angebot';
import PrivateFinanzen from './components/PrivateFinanzen';
import Datenschutz from './components/Datenschutz';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollTop>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Uber-uns" element={<About />} />
            <Route exact path='/Geschaftskunden' element={<Business />} />
            <Route exact path='/Privatkunden/Versicherungen' element={<PrivateKunden />} />
            <Route exact path='/Privatkunden/Finanzen' element={<PrivateFinanzen />} />
            <Route exact path='/DLF' element={<DLF />} />
            <Route exact path='/Angebot' element={<Angebot />} />
            <Route exact path='/Datenschutz-Impressum' element={<Datenschutz />} />

          </Routes>
          <div className='pt-5 mt-5'>
            <Footer />
          </div>
        </ScrollTop>
      </BrowserRouter>
    </div>
  );
}

export default App;

