import React from 'react'
import { useState, useEffect } from 'react'
import AboutIllu from '../../assets/images/About/aboutIllustration.svg'
import AboutIlluMobile from '../../assets/images/About/aboutIlluMobile.svg'

import Transparent from '../../assets/images/About/transparent.svg'
import Consistent from '../../assets/images/About/consistent.svg'
import Indepent from '../../assets/images/About/indepent.svg'

function AboutClouds() {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    return (
        <>
            <div className='px-4 px-lg-5 '>
                <div className="cloudsDiv text-center ">
                    <div className='pb-2 pt-5'>
                        <span className='homeFirstTitle'>
                            Über uns
                        </span>
                    </div>
                    <div>
                        <span className='homeSecondTitle'>
                            Unternehmensphilosophie.
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div className="py-5 my-5 text-end">
                    <div className='iluDiv'>
                        {(screenWidth > 576.98) && (
                            <img src={AboutIllu} alt="" />
                        )}
                        {(screenWidth < 576.98) && (
                            <img src={AboutIlluMobile} alt="" />
                        )}
                    </div>
                </div>
                <div className='text-center container-lg px-4 px-md-5 pb-5 mb-5 col-12 col-lg-10'>
                    <span className='greyTextAbout'>
                        Unsere Unternehmensphilosophie basiert auf den Grundsätzen: Unabhängigkeit, Objektivität, Kompetenz, Leidenschaft und Vertraulichkeit.
                        Als unabhängiger Finanzdienstleister beraten wir unsere Kunden individuell. Wir bieten Ihnen nachhaltige Konzepte, ob als Privatperson oder KMU. Wir sorgen für Ihre finanzielle Absicherung unter dem Aspekt der grösst möglichen Freiheit. Unsere Bewertungen erfolgen unabhängig von Dritten, unsere <br></br> Beratung berücksichtigt Chancen und Risiken.<br></br>
                        Wir entwickeln innovative Lösungen, die Ihnen persönlich oder Ihrem Unternehmen einen echten Mehrwert bieten. Ehrlichkeit steht dabei im Mittelpunkt.
                    </span>
                </div>
                <div className='cloudsDiv2 text-center container-lg px-0 px-md-5 px-xl-0 pb-5 mb-5'>
                    <div className="row gy-5 gy-md-5 gx-0 justify-content-center">
                        <div className="col-12 col-md-3">
                            <div className='pb-3'>
                                <img className='aboutImgs' src={Transparent} alt="" />
                            </div>
                            <div>
                                <span style={{ fontWeight: 700 }} className='homeSecondTitle'>Transparent</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className='pb-3'>
                                <img className='aboutImgs' src={Consistent} alt="" />
                            </div>
                            <div>
                                <span style={{ fontWeight: 700 }} className='homeSecondTitle'>Nachhaltig</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className='pb-3'>
                                <img className='aboutImgs' src={Indepent} alt="" />
                            </div>
                            <div>
                                <span style={{ fontWeight: 700 }} className='homeSecondTitle'>Unabhängig</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="text-center container-lg px-4 px-md-5 pb-5 mb-5 col-12 col-lg-10">
                    <span className='greyTextAbout'>
                        Unsere Konzepte basieren auf Qualität, Kompetenz und Fairness. Zielgerichtet und lösungsorientiert behalten wir Ihren Erfolg im Blick. Dabei vertrauen wir auf unser breit aufgestelltes Partnernetzwerk.Wir besitzen Erfahrung, Professionalität und Engagement.
                        Bei Beratung und Umsetzung handeln wir zukunftsorientiert, denken weiter und beschreiten voll Zuversicht neue Wege. Wir legen grossen Wert auf eine langjährige, vertrauensvolle Zusammenarbeit mit unseren Kunden.
                    </span>
                </div>

            </div>
        </>

    )
}

export default AboutClouds