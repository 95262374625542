import React from 'react'
import Insurance from '../../assets/images/Private/insurance.svg'
import Sachver from '../../assets/images/Private/sachver.svg'
import { Link } from 'react-router-dom'

function SecondSection(props) {
    return (
        <div>
            <div className="privateBlueBg">
                <div className="container-lg px-4 px-md-5 px-lg-0">
                    <div className="py-0 py-lg-5 mt-5" id='Krankenversicherung'>
                        <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto order-2 order-md-1">
                                <div className='text-center'>
                                    <img src={Insurance} className="businessImgs" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto order-1 order-md-2">
                                <div className="pb-4">
                                    <span className='businessSecondTitle fs-3'>Krankenversicherung</span>
                                </div>

                                <div className="pb-3">
                                    <span>Das Angebot an Krankenkassen ist in der Schweiz vielfältig.</span>
                                </div>
                                <div className="pb-4 pb-sm-3">
                                    Die Versicherungen unterscheiden sich in Ihren Leistungen und im Preisgefüge.

                                </div>
                                {(props.screenWidth > 576.98) && (
                                    <>
                                        <div className="pb-3">
                                            Daher lohnt sich eine Beratung welche massgeschneidert nach ihren Bedürfnissen ist.

                                        </div>
                                        <div className="pb-4">
                                            Wir kennen die Bedingungen der einzelnen Krankenkassen und zeigen Ihnen, wie Sie Ihr finanzielles Budget entlasten. Durch die richtige Wahl der Franchise lassen sich Prämienverbilligung von bis zu 45 Prozent erreichen.

                                        </div>
                                    </>
                                )}
                                <div className="pb-4">
                                    {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                                </div>
                                <div>
                                    <Link to='/Angebot'>
                                        <button className='homeFirstSection py-2'>
                                            Angebot einholen
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-5 my-5" id='Sachversicherung'>
                        <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto order-1">
                                <div className="pb-4">
                                    <span className='businessSecondTitle fs-3'>Sachversicherung</span>
                                </div>

                                <div className="pb-4 pb-sm-3">
                                    <span>Sachen können durch Naturgewalten, Unfälle oder sonstige Gefahren Schaden nehmen. Teils entstehen dermassen hohe Schäden, dass einzelne Person diese nicht mehr bewältigen können. Daher ist ein Versicherungsschutz essenziell!
                                    </span>
                                </div>
                                {(props.screenWidth > 576.98) && (
                                    <>
                                        <div className="pb-3">
                                            Zu den klassischen Sachversicherungen zählen die Autoversicherungen für PW und Motorrad sowie Hausrat-, Gebäude-, Haftpflicht- und Rechtsschutzversicherungen.

                                        </div>
                                        <div className="pb-4">
                                            <div className="pb-3">
                                                <div className='pb-1'>
                                                    <span style={{ fontWeight: 700 }}>Hausratversicherung</span>
                                                </div>
                                                <div>
                                                    <span>Die Hausratversicherung greift bei Diebstahl, Wasserschäden, Glasbruch oder Brand bis zur Höhe der Versicherungssumme.</span>
                                                </div>
                                            </div>
                                            <div className="pb-3">
                                                <div className='pb-1'>
                                                    <span style={{ fontWeight: 700 }}>Gebäude</span>
                                                </div>
                                                <div>
                                                    <span>Die Hausratversicherung greift bei Diebstahl, Wasserschäden, Glasbruch oder Brand bis zur Höhe der Versicherungssumme.</span>
                                                </div>
                                            </div>
                                            <div className="pb-3">
                                                <div className='pb-1'>
                                                    <span style={{ fontWeight: 700 }}>Auto</span>
                                                </div>
                                                <div>
                                                    <span>Die Autohaftpflicht tritt für Schäden ein, die Sie anderen Personen oder Sachen mit Ihrem Fahrzeug zufügen, sie ist Pflicht. Dahingegen deckt die Kasko- und Teilkaskoversicherung Schäden am eigenen Auto ab.</span>
                                                </div>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: 700 }}>Rechtschutz</span>

                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="pb-4">
                                    {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                                </div>
                                <div>
                                    <Link to='/Angebot'>
                                        <button className='homeFirstSection py-2'>
                                            Angebot einholen
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto order-2 order-md-1">
                                <div className='text-center'>
                                    <img src={Sachver} className="businessImgs" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SecondSection