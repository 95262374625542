import React from 'react'
import Steuer from '../../assets/images/Private/steuer.svg'
import Hypothek from '../../assets/images/Private/hypothek.svg'
import { Link } from 'react-router-dom'

function FifthSection(props) {
    return (
        <div>
            <div className="container-lg px-4 px-md-5 px-lg-0 mt-5" id='Steuerberatung'>
                <div className="mt-5 pt-5" >
                    <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto order-2 order-md-1">
                            <div className='text-center'>
                                <img src={Steuer} className="businessImgs" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto order-1 order-md-2">
                            <div className="pb-4">
                                <span className='businessSecondTitle fs-3'>Steuerberatung</span>
                            </div>
                            <div className="pb-3">
                                <span>Wer zahlt schon gerne Steuern? </span>
                            </div>
                            <div className="pb-4 pb-sm-3">
                                Das Schweizer Steuerrecht bietet zahlreiche Möglichkeiten, Steuern zu sparen.
                            </div>
                            {(props.screenWidth > 576.98) && (
                                <>
                                    <div className="pb-3">
                                        Wir geben Ihnen Tipps, die Kosten zu senken und füllen auf Wunsch Ihre Steuererklärung aus, unabhängig von Ihrem Familienstand – ob Sie ledig sind, verheiratet, in Partnerschaft leben oder Alimente zahlen.
                                    </div>
                                    <div className="pb-3">
                                        Wir übernehmen die Steuerberatung und kümmern uns um Ihre Steuererklärung und eventuelle Einsprachen.
                                    </div>
                                    <div className="pb-4">
                                        Gerne bieten wir Ihnen eine unabhängige und kompetente Beratung an.
                                    </div>
                                </>
                            )}
                            <div className="pb-4">
                                {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                            </div>
                            <div>
                                <Link to='/Angebot'>
                                    <button className='homeFirstSection py-2'>
                                        Angebot einholen
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-5 my-5" id='Hypotheke'>
                    <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto order-1">
                            <div className="pb-4">
                                <span className='businessSecondTitle fs-3'>Hypotheke</span>
                            </div>
                            <div className="pb-3">
                                <span>
                                    Sie träumen von einem Eigenheim? Oder möchten Ihre bestehende Hypothek umschulden?
                                </span>
                            </div>
                            <div className="pb-4 pb-sm-3">
                                Laut NZZ verschenken Schweizer bei der Hypothek viel Geld. Vielfach beschränken sie sich auf wenige Angebote oder wählen direkt die Hausbank.
                            </div>
                            {(props.screenWidth > 576.98) && (
                                <>
                                    <div className="pb-3">
                                        Welches ist das beste Angebot für Ihre Hypothek?
                                    </div>
                                    <div className="pb-4">
                                        Bei der Wahl sollten nicht nur niedrige Zinsen eine Rolle spielen, sondern auch die Konditionen für eine Amortisation, eventuelle Vorfälligkeitsentschädigungen und sogenannte Knebelverträge.
                                    </div>
                                </>
                            )}
                            <div className="pb-4">
                                {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                            </div>
                            <div>
                                <Link to='/Angebot'>
                                    <button className='homeFirstSection py-2'>
                                        Angebot einholen
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-5 my-md-auto order-2 order-md-1">
                            <div className='text-center'>
                                <img src={Hypothek} className="businessImgs" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FifthSection