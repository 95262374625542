import React from 'react'
import Think from '../../assets/images/Private/thinking.svg'
import { Link } from 'react-router-dom'

function Clouds(props) {
    return (
        <div>
            <div className="px-4 px-lg-5">
                <div className="cloudsDivB text-center mb-5 pb-5">
                    <div className='pb-2 pt-5'>
                        <span className='homeFirstTitle'>
                            Finanzen
                        </span>
                    </div>
                    <div>
                        <span className='homeSecondTitle'>
                        Wir kennen die Finanzdienstleister, Steuerbehörden und Tipps und Tricks zu Budgetfragen.<br></br> Durch einen Vergleich lässt sich Geld einsparen und die Bedingungen optimieren.
                        </span>
                    </div>
                </div>
                <div className="container-lg px-0 px-md-5 px-lg-0 pt-5 mt-5" id='Finanzplanung'>
                    <div className="pt-5 pt-md-0 pb-0 pb-md-5" >
                        <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto">
                                <div className="pb-4">
                                    <span className='businessSecondTitle fs-3'>Finanzplanung</span>
                                </div>
                                <div className="pb-4 pb-sm-3">
                                    <span>Eine solide Finanzplanung basiert im Wesentlichen auf vier aufeinander aufbauenden Bausteinen.
                                    </span>
                                </div>
                                {(props.screenWidth > 576.98) && (
                                    <>
                                        <div className="pb-3">
                                            Ermitteln Sie zunächst Ihr eigenes Sparpotenzial, sprich die Differenz zwischen Einnahmen und Ausgaben.
                                            Liegen die Ausgaben über den Einnahmen, verbleibt nichts zum Sparen, – der Schuldenberg wächst.
                                        </div>
                                        <div className="pb-4">
                                            Hier hilft nur eine Budgetplanung, um die Ausgaben zu senken.
                                            Überprüfen Sie Ihre Lebenshaltungskosten. Wo lässt sich etwas einsparen, ohne an der Lebensqualität zu verlieren?
                                        </div>
                                    </>
                                )}
                                <div className="pb-4">
                                    {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                                </div>
                                <div>
                                    <Link to='/Angebot'>
                                        <button className='homeFirstSection py-2'>
                                            Angebot einholen
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto">
                                <div className='text-center'>
                                    <img src={Think} className="businessImgs" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clouds