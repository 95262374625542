import React from 'react'
import Vermo from '../../assets/images/Private/vermo.svg'
import Pension from '../../assets/images/Private/pension.svg'

import { Link } from 'react-router-dom'

function FourthSection(props) {
    return (
        <div>
            <div className="privateBlueBg">
                <div className="container-lg px-4 px-md-5 px-lg-0">
                    <div className="py-0 py-lg-5 mt-5">
                        <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto order-2 order-md-1">
                                <div className='text-center'>
                                    <img src={Pension} className="businessImgs" alt="" />
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto order-1 order-md-2">
                                <div className="pb-4">
                                    <span className='businessSecondTitle fs-3'>Pensionsplanung</span>
                                </div>

                                <div className="pb-3">
                                    <span>Ihre Planung hängt davon ab, was Sie nach Ihrer Pensionierung unternehmen möchten. </span>
                                </div>
                                <div className="pb-3">
                                    Planen Sie Reisen um die Welt oder möchten Sie einfach nur so lange wie möglich Ihre Gesundheit erhalten?
                                </div>
                                {(props.screenWidth > 576.98) && (
                                    <>
                                        <div className="pb-3">
                                            Haben Sie vor, selbst bestimmter zu leben oder möchten Sie etwas Neues lernen?

                                        </div>
                                        <div className="pb-3">
                                            Oder legen Sie einfach nur Wert darauf, in finanziell gesicherten Verhältnissen von Ihrer Pension zu leben?
                                        </div>
                                        <div className="pb-4">
                                            Je nach Lebenswunsch fällt die Pensionsplanung unterschiedlich aus.
                                        </div>
                                    </>
                                )}
                                <div className="pb-4">
                                    {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                                </div>
                                <div>
                                    <Link to='/Angebot'>
                                        <button className='homeFirstSection py-2'>
                                            Angebot einholen
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-5 my-5">
                        <div className="row gx-0 gx-md-4 gx-lg-5 gy-5 gy-md-0 justify-content-center">
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto order-1">
                                <div className="pb-4">
                                    <span className='businessSecondTitle fs-3'>Vermögensaufbau</span>
                                </div>
                                <div className="pb-3">
                                    <span>Wie Vermögen aufbauen? Welches ist die richtige Strategie? </span>
                                </div>
                                <div className="pb-4 pb-sm-3">
                                    Gerade in Zeiten mit niedrigen Zinsen kein einfaches Unterfangen, trotzdem gibt es noch sichere Anlage-Möglichkeiten, die Rendite abwerfen.
                                </div>
                                {(props.screenWidth > 576.98) && (
                                    <>
                                        <div className="pb-3">
                                            Gerne beraten wir Sie und zeigen Ihnen passende Anlageformen auf.
                                        </div>
                                        <div className="pb-4">
                                            Dabei behalten wir Ihr Risikoprofil im Auge, unabhängig ob Sie eine Einmalanlage suchen oder monatliche Zahlungen in einen Sparplan bevorzugen.

                                        </div>
                                    </>
                                )}
                                <div className="pb-4">
                                    {/* <span className='businessBlueSpan'>lorem ipsum lorem ipsum </span> */}
                                </div>
                                <div>
                                    <Link to='/Angebot'>
                                        <button className='homeFirstSection py-2'>
                                            Angebot einholen
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-5 my-md-auto order-2 order-md-1">
                                <div className='text-center'>
                                    <img src={Vermo} className="businessImgs" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FourthSection