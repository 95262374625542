import React from 'react'
import '../../assets/css/Header.css'
import Logo from '../../assets/images/logo.svg'
import '../../assets/css/Footer.css'
import { HashLink } from 'react-router-hash-link';

function Footer() {
    return (
        <div>
            <div className='px-4 pb-4 px-md-5'>
                <div className='container-lg gx-0'>
                    <div className="row g-5 justify-content-center">
                        <div className="col-12 col-md-6">
                            <div className='pb-2'>
                                <img src={Logo} alt="" />
                            </div>
                            <div className='pb-2'>
                                <span style={{ color: '#948E8E', fontWeight: 500 }}>Fair. Kompetent. Lokal.</span>
                            </div>

                        </div>
                        <div className="col-12 col-md-6">
                            <div className="row gy-5 justify-content-between">
                                <div className="col-12 col-md-auto">
                                    <div className='pb-1'>
                                        <span className='footerTitle'>DL Finance</span>
                                    </div>
                                    <div>
                                        <HashLink to='/Uber-uns' className='text-decoration-none'>
                                            <span className='footerUnderTitle'>
                                                Über uns
                                            </span>
                                        </HashLink>

                                    </div>
                                    <div>
                                        <span className='footerUnderTitle'>
                                            Team
                                        </span>
                                    </div>
                                    <div>
                                        <span className='footerUnderTitle'>
                                            Jobs
                                        </span>
                                    </div>
                                    <div>
                                        <HashLink to='/Datenschutz-Impressum' className='text-decoration-none'>
                                            <span className='footerUnderTitle'>Datenschutz</span>
                                        </HashLink>
                                    </div>
                                    <div>
                                        <HashLink to='/Datenschutz-Impressum' className='text-decoration-none'>
                                            <span className='footerUnderTitle'>Impressum</span>
                                        </HashLink>

                                    </div>
                                </div>
                                <div className="col-12 col-md-auto">
                                    <div className='pb-1'>
                                        <span className='footerTitle'>Versicherungen</span>
                                    </div>
                                    <div>
                                        <HashLink to="/Privatkunden/Versicherungen#Krankenversicherung" className='text-decoration-none'>

                                            <span className='footerUnderTitle'>
                                                Krankenkasse
                                            </span>
                                        </HashLink>
                                    </div>
                                    <div>
                                        <HashLink to="/Privatkunden/Versicherungen#Sachversicherung" className='text-decoration-none'>

                                            <span className='footerUnderTitle'>
                                                Autoversicherung
                                            </span>
                                        </HashLink>
                                    </div>
                                    <div>
                                        <HashLink to="/Privatkunden/Versicherungen#Sachversicherung" className='text-decoration-none'>
                                            <span className='footerUnderTitle'>
                                                Rechtsschutz
                                            </span>
                                        </HashLink>
                                    </div>
                                    <div>
                                        <HashLink to="/Privatkunden/Versicherungen#Sachversicherung" className='text-decoration-none'>
                                            <span className='footerUnderTitle'>
                                                Hausrat
                                            </span>
                                        </HashLink>
                                    </div>
                                    <div>
                                        <HashLink to="/Privatkunden/Finanzen#Vorsorgeberatung" className='text-decoration-none'>
                                            <span className='footerUnderTitle'>
                                                Vorsorge
                                            </span>
                                        </HashLink>

                                    </div>
                                    <div>
                                        <HashLink to="/Geschaftskunden" className='text-decoration-none'>

                                            <span className='footerUnderTitle'>
                                                KMU
                                            </span>
                                        </HashLink>

                                    </div>
                                </div>
                                <div className="col-12 col-md-auto">
                                    <div className='pb-1'>
                                        <span className='footerTitle'>Finanzen</span>
                                    </div>
                                    <div>
                                        <HashLink to="/Privatkunden/Finanzen#Hypotheke" className='text-decoration-none'>
                                            <span className='footerUnderTitle'>
                                                Hypotheken
                                            </span>
                                        </HashLink>
                                    </div>
                                    <div>
                                        <HashLink to="/Privatkunden/Finanzen#Finanzplanung" className='text-decoration-none'>

                                            <span className='footerUnderTitle'>
                                                Finanzplanung
                                            </span>
                                        </HashLink>

                                    </div>
                                    <div>
                                        <HashLink to="/Privatkunden/Finanzen#Steuerberatung" className='text-decoration-none'>

                                            <span className='footerUnderTitle'>
                                                Steuerberatung
                                            </span>
                                        </HashLink>

                                    </div>
                                </div>
                                <div className="col-12 col-md-auto">
                                    <div className='pb-1'>
                                        <span className='footerTitle'>Social Media</span>
                                    </div>
                                    <div>
                                        <span className='footerUnderTitle'>
                                            Instagram
                                        </span>
                                    </div>
                                    <div>
                                        <span className='footerUnderTitle'>
                                            Linkedln
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer