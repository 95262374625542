import React from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function HomeFirstSection() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])
  return (
    <div>
      <div className='text-center px-4 cloudsDiv'>
        <div className='pb-2 pt-5'>
          <span className='homeFirstTitle '>Fair. Kompetent. Lokal</span>
        </div>
        <div className='pb-4 mb-2'>
          <span className='homeSecondTitle'>
            Das sind die Gründe wieso unsere Kunden uns lieben.
          </span>
        </div>
        {(screenWidth < 786.98) && (
          <div className='pb-4 mb-2'>
            <Link to='/Angebot'>
              <button className='homeFirstSection py-2'>
                Angebot einholen
              </button>
            </Link>
          </div>
        )}

      </div>
    </div>
  )
}

export default HomeFirstSection