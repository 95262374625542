import React from 'react'
import '../assets/css/Angebot.css'
import Girl from '../assets/images/Angebot/girl.svg'
import Hand from '../assets/images/Angebot/hand.svg'

function Angebot() {
    return (
        <div>
            <div className='px-4 px-lg-5 '>
                <div className="cloudsDiv text-center ">
                    <div className='pb-2 pt-5'>
                        
                    </div>
                    <div>
                        <span className='homeSecondTitle hideMobile' style={{ visibility: 'hidden' }}>
                            t <br></br> t
                        </span>
                    </div>
                </div>
            </div>
            <div className="container-lg px-4 px-md-5 px-lg-4 angebotPaddingBottom" style={{ position: 'relative' }}>
                <div className="handDiv">
                    <img src={Hand} alt="" />
                </div>
                <div className="row g-0">
                    <div className="col-0 col-md-2">
                        <div>
                            <img src={Girl} className='girl' alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-7">
                        <div className="angebotBlueBg p-4 p-md-5">
                            <div className="py-0 py-sm-5 ps-0 ps-md-4 ps-lg-5">
                                <div className='pb-3'>
                                    <div>
                                        <span>Name</span>
                                    </div>
                                    <div>
                                        <input type="text" className='angebotInputs form-control' />
                                    </div>
                                </div>
                                <div className='pb-3'>
                                    <div>
                                        <span>E-mail</span>
                                    </div>
                                    <div>
                                        <input type="email" className='angebotInputs form-control' />
                                    </div>
                                </div>
                                <div className='pb-3'>
                                    <div>
                                        <span>Telefon</span>
                                    </div>
                                    <div>
                                        <input type="text" className='angebotInputs form-control' />
                                    </div>
                                </div>
                                <div className='pb-3'>
                                    <div>
                                        <span>Nachricht</span>
                                    </div>
                                    <div>
                                        <input type="text" className='angebotInputs form-control' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="angebotBlueBg2 py-4 py-lg-5 ps-4 ps-xl-5 pe-4">
                            <div className="py-0 py-xl-3">
                                <div className="pb-3">
                                    <span style={{ fontWeight: 500, fontSize: '18px' }}>Kontaktiere uns</span>
                                </div>
                                <div className="pb-2">
                                    <span style={{ fontWeight: 300 }}>+41 31 918 03 03</span>
                                </div>
                                <div className="pb-4">
                                    <span style={{ fontWeight: 300 }}>info@dl-finance.ch</span>
                                </div>
                                <div className="pb-3">
                                    <span style={{ fontWeight: 500, fontSize: '18px' }}>Standorte</span>
                                </div>
                                <div className="pb-2">
                                    <span style={{ fontWeight: 300 }}>Agentur in Bern</span>
                                </div>
                                <div className="pb-2">
                                    <span style={{ fontWeight: 300 }}>Agentur in Thun</span>
                                </div>
                                <div className="pb-4">
                                    <span style={{ fontWeight: 300 }}>Hauptsitz in Rothrist</span>
                                </div>
                                <div className="pb-3">
                                    <span style={{ fontWeight: 500, fontSize: '18px' }}>Öffnungszeiten.</span>
                                </div>
                                <div className="pb-2">
                                    <span style={{ fontWeight: 300 }}>Montag - Freitag: 08:00 - 19:00</span>
                                </div>
                                <div>
                                    <span style={{ fontWeight: 300 }}>Samstag: 10:00 - 14:00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Angebot