import React from 'react'
import '../assets/css/About.css'
import AboutClouds from './about/AboutClouds'
import Faq from './about/Faq'
function About() {
  return (
    <div>
        <div>
            <AboutClouds />
            <Faq />
        </div>
    </div>
  )
}

export default About