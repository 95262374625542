import React from 'react'
import Categories from './dlf/Categories'
import Title from './dlf/Title'
import '../assets/css/Dlf.css'
import DLFaq from './dlf/DLFaq'
function Dlf() {
    return (
        <div>
            <div className=''>
                <Title />
            </div>
            <div className='pb-0 pb-md-5'>
                <Categories />
            </div>
            <div>
                <DLFaq />
            </div>
        </div>
    )
}

export default Dlf